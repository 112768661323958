import React from "react";

const Recycling = (props) => {
    return (<>
        <section id="recycling" className="action-parallax"
            style={{ color: "#fff !important", "background": "rgba(0, 100, 7, 0.80)", "borderTop": "1px solid #ddd" }}>
            <div className="container-fluid text-center wow fadeIn textWhite">
                <h2 className="editable section-title col-md-offset-1 textWhite" id="untitled-region-5" style={{ color: "#fff !Important" }}>
                    Recycling
                </h2>
                <div className="row">
                    <div className="col-md-6 col-md-offset-1" style={{ "textAlign": "justify" }}>
                        <p id="untitled-region-6" style={{ "textAlign": "left" }}>
                            <p><em>Sunshine Recycling offers Solutions that meet your needs Today and into Tomorrow</em></p>
                            <p>
                                Sunshine Recycling prides itself on the fact that we are a Certified NPDES (<em>
                                    National Pollutant
                                    Discharge Elimination System
                                </em>) Industrial Storm water drain-off facility.&nbsp; Sunshine
                                Recycling currently has ELEVEN oil-water separators in our plant protecting our community from oil
                                and other harmful substances associated with scrap metal.
                            </p>
                            <p>
                                <strong><span style={{ "textDecoration": "underline" }}>LEED</span></strong><strong>
                                    &nbsp;– SUNSHINE
                                    RECYCLING is qualified to assist with projects seeking LEED points. &nbsp;We have extensive
                                    construction experience and can<strong>
                                        &nbsp;coordinate the recycling and disposal of C&amp;D
                                        materials for your project.
                                    </strong>&nbsp;&nbsp;
                                </strong>
                            </p>
                            <p>
                                Sunshine Recycling delivers a full spectrum of integrated metals recovery and <a title="Recycling Services at Sunshine Recycling"
                                    href="http://www.sunshinerecycle.com/">recycling services</a>.
                            </p>
                            <p>
                                Sunshine Recycling has a knowledgeable staff and utilizes the latest State-of-the-Art technology and
                                <a title="Metal Recycling Equipment at Sunshine Recycling"
                                    href="http://www.sunshinerecycle.com/equipment/">equipment</a> that assures the highest
                                standards in the industry.
                            </p>
                            <p>
                                From collection to processing, management, transportation and sales, Sunshine Recycling is an
                                industry leader you can count on.
                            </p>
                            <p>
                                Fueled by a dedication to quality, integrity and technical proficiency, the company’s continuing
                                growth is predicated on delivering the highest standards of service to each and every client.
                            </p>
                        </p>
                    </div>
                    <div className="col-md-4 col-md-offset-1">
                        <div className="embed-responsive embed-responsive-16by9">
                            <img id="untitled-region-7" className="img-responsive editable products-image"
                                src="../img/GlobalRecyclingDay-2019.jpg" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>);
}

export default Recycling;