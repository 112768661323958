import React from 'react'

function ContactUs(props) {
    return (<>
        {/* contact */}
        <section id="contact" style={{ background: 'rgba(255, 255, 255, 1.0)', borderTop: '1px solid #ddd' }}>
            <div className="container wow fadeIn">
                <div className="row">
                    <div className="col-md-6">
                        <h2 className="editable section-title" id="untitled-region-42">How May We Be Of Service</h2>
                        <p className="lead text-muted editable" id="untitled-region-43"><strong><span style={{ textDecoration: 'underline' }}>MAIN OFFICE</span></strong><br />{props.companyInfo.companyAddress}, {props.companyInfo.companyCity}, {props.companyInfo.companyState} {props.companyInfo.companyPhone}</p>
                        <div className="row">
                            <h3 className="editable" id="untitled-region-44"><i className="icon fas fa-phone" /> {props.companyInfo.companyPhone}</h3>
                            <h4 className="lead text-muted editable" id="untitled-region-45"><i className="icon far fa-envelope" /> please use the form to contact us</h4>
                            <a href="https://www.facebook.com/SunshineJoeRich" target="_blank"><img src="img/facebook-icon.svg" alt="f" title="Follow us on Facebook" height={30} /></a> &nbsp;
                            <a href="#" target="_blank"><img src="img/instagram-icon.svg" alt="i" title="Follow us on Instagram" height={30} /></a>
                            <div className="col-md-6" style={{ marginLeft: '-50px', display: 'none' }}>
                                <img src="img/sunshine-associations2.jpg" />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-5 col-md-offset-1">
                        <h2 className="editable section-title" id="untitled-region-46">Contact Us</h2>
                        <p className="editable" id="untitled-region-47">If you’d like a quote or need to contact us for any other reason, please enter your details below.</p>
                        <form method="post" action="../ajax/sendEmail.aspx" fromemail="jrich@sunshinerecycle.com" fromname="Sunshine Recycle" id="contact-form" role="form">
                            <div className="message col-xs-12">
                                <p className="email-loading"><img src="img/loading.gif" alt="" />&nbsp;&nbsp;&nbsp;Sending...</p>
                                <p className="email-success"><i className="icon icon-icon-check-alt2" /> Your message has successfully been sent.</p>
                                <p className="email-failed"><i className="icon icon-icon-close-alt2" /> Something went wrong!</p>
                            </div>
                            <div className="control-group">
                                <div className="form-group floating-label-form-group controls">
                                    <label htmlFor="name" className="sr-only control-label">Your Name</label>
                                    <input type="text" className="form-control input-lg" placeholder="Your Name" id="contact-name" required data-validation-required-message="Please enter name" />
                                    <span className="help-block text-danger" />
                                </div>
                            </div>
                            <div className="control-group">
                                <div className="form-group floating-label-form-group controls">
                                    <label htmlFor="email" className="sr-only control-label">Your Email</label>
                                    <input type="email" className="form-control input-lg" placeholder="Your Email" id="contact-email" required data-validation-required-message="Please enter email" />
                                    <span className="help-block text-danger" />
                                </div>
                            </div>
                            <div className="control-group">
                                <div className="form-group floating-label-form-group controls">
                                    <label htmlFor="phone" className="sr-only control-label">Your Phone</label>
                                    <input type="tel" className="form-control input-lg" placeholder="Your Phone" id="contact-phone" required data-validation-required-message="Please enter phone number" />
                                    <span className="help-block text-danger" />
                                </div>
                            </div>
                            <div className="control-group">
                                <div className="form-group floating-label-form-group controls">
                                    <label htmlFor="message" className="sr-only control-label">Message</label>
                                    <textarea rows={2} className="form-control input-lg" placeholder="Message" id="contact-message" required data-validation-required-message="Please enter a message." aria-invalid="false" defaultValue={""} />
                                    <span className="help-block text-danger" />
                                </div>
                            </div>
                            <div id="success" />
                            <button type="submit" className="btn border-button-black" id="submit">Send</button>
                        </form>
                    </div>
                </div>
            </div>
        </section>

    </>)
}

export default ContactUs