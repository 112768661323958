import React from 'react'

function ProductsServices() {
    return (<>
        {/* Products and Services */}
        <section id="products-services" className="action-parallax section-panel" style={{ background: 'rgba(255, 255, 255, 0.85)', borderTop: '1px solid #ddd' }}>
            <div className="container-fluid text-center wow fadeIn">
                <h2 className="editable section-title col-md-offset-1" id="untitled-region-5">Aggregate<span className="section-title-sub">&nbsp;<i className="fa-solid fa-caret-right" />&nbsp;Products and Services</span></h2>
                <div className="row">
                    <div className="col-md-5 col-md-offset-1" style={{ textAlign: 'justify' }}>
                        <div className="entry-content">
                            <p>We are a leading supplier of Recycled Aggregates used in construction and building materials. We offer any size rock to meet your need. Our standard products include but are not limited to:</p>
                            <h2>CR 14</h2>
                            <p><a href="#"><img className="alignnone size-full wp-image-345 products-image img-fluid" title="CR 14" src="img/Quarter_Rock_CR14.jpg" alt="" /></a></p>
                            <h2 style={{ display: 'none' }}>57 RCA</h2>
                            <p style={{ display: 'none' }}><img className="alignnone size-full wp-image-347 products-image img-fluid" title="57 RCA" src="img/57_stone_fullframe.jpg" alt="" /></p>
                            <h2>4MOD (2-1/2” minus)</h2>
                            <p><img className="alignnone size-full wp-image-348 products-image img-fluid" title="4MOD" src="img/4_MOD_25_inch_tape.jpg" alt="" /></p>
                            <h2>Recycled Asphalt</h2>
                            <p><img className="alignnone size-full wp-image-347 products-image img-fluid" title="57 RCA" src="img/recycled-asphalt.jpg" alt="" /></p>
                        </div>{/* .entry-content */}
                    </div>
                    <div className="col-md-5">
                        <div className="embed-responsive embed-responsive-16by9">
                            <iframe src="http://www.youtube-nocookie.com/embed/M8NdcB74Fs8?rel=0" className="embed-responsive-item products-video" frameBorder={0} />
                        </div>
                        <div className="products-services-text" style={{ textAlign: 'justify !Important', marginTop: '50px' }}>
                            <p><strong>Sunshine Aggregate LLC offers the following discounts on all our products:</strong></p>
                            <ul>
                                <li>100-499 Tons – $1.00 per ton discount</li>
                                <li>500-999 Tons – $1.50 per ton discount</li>
                                <li>1000 Tons and above – $2.00 per ton discount.</li>
                            </ul>
                            <p><strong>Sunshine Aggregate LLC offers the unique ability to recycle C&amp;D material on your job site!</strong></p>
                            <p>We are equipped with the 2012 Terex Power Screen MetroTrac and the Powerscreen® Chieftain 1400 which gives us the Recycling capacity to Process up to 220 tons per hour.</p>
                            <p><strong>Download brochures:</strong></p>
                            <ul>
                                <li><a href="http://www.sunshinerecycle.com/site/wp-content/themes/sunshine/images/downloads/powerscreen-metrotrak-ha-brochure-page.pdf" target="_blank"> 2012 Terex Power Screen MetroTrac</a></li>
                                <li><a href="http://www.sunshinerecycle.com/site/wp-content/themes/sunshine/images/downloads/chieftain-1400.pdf" target="_blank"> Powerscreen® Chieftain 1400</a></li>
                            </ul>
                            <br />
                            <p><em> Please call for current pricing.</em></p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </>)
}

export default ProductsServices