import React from "react";
import video from '../img/videos/intro-video new.mp4'

const SiteHeader = (props) => {
    return (<>
        {/* Header */}
        <section id="top" rel="preload" className="parallax site-wrapper site-wrapper-top" data-speed={4} data-type="background">
            <div style={{ position: 'absolute', top: 0, left: 0, bottom: 0, right: 0, zIndex: -1 }}>
                <video src="img/videos/intro-video new.mp4" id="front-video" autoPlay muted loop playsInline>
                    Your browser does not support the video tag.
                </video>
            </div>
            <div className="site-wrapper-inner wrapper-fader">
                <div className="cover-container inner2">
                    <div className="cover">
                        <div className="row">
                            <div className="col-xs-12 text-center editable" id="untitled-region-4">
                                <p>
                                    <img src="img/Logo5.png" className="nav-logo" alt="Sunshine Recycling" />
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </>);
}

export default SiteHeader;