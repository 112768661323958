import React from 'react'

function VehiclesHeavyEquipment() {
    return (<>
        {/* Vehicles & Heavy Equipment */}
        <section id="vehicles-and-heavy-equipment" className="action-parallax section-panel" style={{ background: 'rgba(255, 255, 255, 0.85)', borderTop: '1px solid #ddd' }}>
            <div className="container-fluid text-center wow fadeIn">
                <h2 className="editable section-title col-md-offset-1" id="untitled-region-5">Recycling<span className="section-title-sub">&nbsp;<i className="fa-solid fa-caret-right" />&nbsp;Vehicles &amp; Heavy Equipment</span></h2>
                <div className="row">
                    <div className="col-md-6 col-md-offset-1" style={{ textAlign: 'justify' }}>
                        <div className="entry-content">
                            <p>Sunshine Recycling purchases and processes end of life vehicles and heavy equipment at our yard in Orangeburg, SC.</p>
                            <p>Sunshine Recycling prides itself on providing the most efficient and environmentally friendly vehicle and heavy equipment recycling services. Also, Sunshine has a dedicated site specifically for vehicles and heavy equipment to reduce the time it takes to complete the transaction.</p>
                            <p>Sunshine Recycling inspects every vehicle and piece of equipment on receipt at our yard. Once the correct owner identification has been verified, we weigh it on our State Certified Toledo Digital Scale.</p>
                            <p>You can be confident in relying on our environmental credentials while also being assured you’ll get the most competitive rates. If you want to sell your vehicle, simply <a title="Click Here" href="http://www.sunshineautosalvage.com/sell_vehicle.php" target="_blank">click here</a></p>
                        </div>{/* .entry-content */}
                    </div>
                    <div className="col-md-4 col-md-offset-1">
                        <div className="embed-responsive embed-responsive-16by9">
                            <img id="untitled-region-7" className="img-responsive editable products-image" src="img/heavy-equipment.jpg" />
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </>)
}

export default VehiclesHeavyEquipment