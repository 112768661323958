import React from 'react'

function SunshineVideosData(props) {
    var result = [];
    for (var i in props)
        result.push([i, props[i]]);

    return (
        result[0][1].map((_video) => (
            <div className="col-md-6 inline-video">
                <div className="embed-responsive embed-responsive-16by9 mt-10">
                    <iframe src={_video.video} className="embed-responsive-item products-video" frameBorder={0} />
                </div>
            </div>
        ))

    )
}

export default SunshineVideosData