import React from "react";
import CompanyInfo from "./data/CompanyInfo.json";
import SiteInfo from "./data/SiteInfo.json";
import MetaTags from "./components/MetaTags";
import NavBar from "./components/NavBar";
import SiteHeader from "./components/SiteHeader";
import BottomNav from "./components/BottomNav";
import About from "./components/About";
import Recycling from "./components/Recycling";
import FerrousMetals from "./components/FerrousMetals";
import NonFerrousMetals from "./components/NonFerrousMetals";
import ProhibitedMaterials from "./components/ProhibitedMaterials";
import RollOfContainers from "./components/RollOfContainers";
import VehiclesHeavyEquipment from "./components/VehiclesHeavyEquipment";
import MetalsPurchased from "./components/MetalsPurchased";
import Plant from "./components/Plant";
import Equipment from "./components/Equipment";
import DomesticServices from "./components/DomesticServices";
import IndustrialManufacturing from "./components/IndustrialManufacturing";
import MobileCarCrushing from "./components/MobileCarCrushing";
import SiteMaintenance from "./components/SiteMaintenance";
import Customers from "./components/Customers";
import IndustrialPhotos from "./components/IndustrialPhotos";
import DomesticPhotos from "./components/DomesticPhotos";
import SunshineVideos from "./components/SunshineVideos";
import Aggregate from "./components/Aggregate";
import ProductsServices from "./components/ProductsServices";
import RecycledAggregate from "./components/RecycledAggregate";
import Projects from "./components/Projects";
import Faq from "./components/Faq";
import ContactUs from "./components/ContactUs";
import BinServices from "./components/BinServices";
import SiteFooter from "./components/SiteFooter";

let companyInfo = JSON.parse(JSON.stringify(CompanyInfo))[0].companyData;
let siteInfo = JSON.parse(JSON.stringify(SiteInfo));

export default function App() {
  return (<>
    <MetaTags companyInfo={companyInfo} />
    <NavBar headerData={siteInfo[0]} />
    <SiteHeader />
    <BottomNav />
    <About />
    <Recycling />
    <FerrousMetals />
    <NonFerrousMetals />
    <ProhibitedMaterials />
    <RollOfContainers />
    <VehiclesHeavyEquipment />
    <MetalsPurchased />
    <Plant />
    <Equipment />
    <DomesticServices />
    <IndustrialManufacturing />
    <MobileCarCrushing />
    <SiteMaintenance />
    <Customers customerList={siteInfo[1]} />
    <IndustrialPhotos industrialPhotosList={siteInfo[3]} />
    <DomesticPhotos domesticPhotosList={siteInfo[4]} />
    <SunshineVideos videoList={siteInfo[2]} />
    <Aggregate />
    <ProductsServices />
    <RecycledAggregate />
    <Projects />
    <Faq />
    <ContactUs companyInfo={companyInfo} />
    <BinServices />
    <SiteFooter companyInfo={companyInfo} />
  </>);
}
