import React from 'react'

function IndustrialPhotosData(props) {
    var result = [];
    for (var i in props)
        result.push([i, props[i]]);

    return (
        result[0][1].map((_industrial) => (
            <li><img src={_industrial.image} alt={_industrial.alt} /></li>
        ))
    )
}

export default IndustrialPhotosData