import React from 'react'

function RecycledAggregate() {
    return (<>
        {/* Recycled Construction Aggregates */}
        <section id="recycled-construction-aggregates" className="action-parallax section-panel" style={{ background: 'rgba(255, 255, 255, 0.85)', borderTop: '1px solid #ddd' }}>
            <div className="container-fluid text-center wow fadeIn">
                <h2 className="editable section-title col-md-offset-1" id="untitled-region-5">Aggregate<span className="section-title-sub">&nbsp;<i className="fa-solid fa-caret-right" />&nbsp;Recycled Construction Aggregates</span></h2>
                <div className="row">
                    <div className="col-md-6 col-md-offset-1" style={{ textAlign: 'justify' }}>
                        <div className="entry-content">
                            <p>As a major supplier of primary recycled construction aggregates, Sunshine Aggregate LLC offers a broad selection of recycled concrete, asphalt and brick. We also offer the unique ability to recycle C&amp;D materials on your construction site.  Sunshine Aggregate LLC is strategically located for quick delivery and can fulfill custom orders of any size.</p>
                            <p><strong>Recycled Crushed Concrete</strong></p>
                            <p>Our recycled crushed rock includes granite and cement.  Progressive stages of crushing and screening enable us to produce a variety of Recycled aggregate sizes to meet your needs.</p>
                            <p><strong>Our recycled aggregates are used in:</strong></p>
                            <ul>
                                <li>ready mix and precast concrete</li>
                                <li>road construction and surfacing</li>
                                <li>rail track ballast</li>
                                <li>water filtration</li>
                                <li>pipe bedding</li>
                            </ul>
                            <p><strong>Products offered:</strong></p>
                            <ul>
                                <li>crushed materials</li>
                                <li>dusts</li>
                                <li>face materials</li>
                                <li>fill materials</li>
                                <li>single size and graded materials</li>
                                <li>sub-bases</li>
                            </ul>
                            <p>We are a leading supplier of Recycled Aggregates used in construction and building materials.</p>
                        </div>{/* .entry-content */}
                    </div>
                    <div className="col-md-4 col-md-offset-1">
                        <img id="untitled-region-7" className="img-responsive editable" src="img/72690.jpeg" />
                    </div>
                </div>
            </div>
        </section>

    </>)
}

export default RecycledAggregate