import React from 'react'

function DomesticServices() {
    return (<>
        {/* Domestic Services */}
        <section id="domestic-services" className="action-parallax section-panel" style={{ background: 'rgba(255, 255, 255, 0.85)', borderTop: '1px solid #ddd' }}>
            <div className="container-fluid text-center wow fadeIn">
                <h2 className="editable section-title col-md-offset-1" id="untitled-region-5">Services<span className="section-title-sub">&nbsp;<i className="fa-solid fa-caret-right" />&nbsp;Domestic Services</span></h2>
                <div className="row">
                    <div className="col-md-6 col-md-offset-1" style={{ textAlign: 'justify' }}>
                        <div className="entry-content">
                            <p><strong>Sunshine Recycling has been recycling metals with the community since 1996.</strong></p>
                            <p>Sunshine Recycling can assist with a service that can help you recycle every type of “home style” scrap metal, including refrigerators, washing machines, dryers, stoves, hot water tanks, old tubs, and even the old plumbing. Our services cater to all types of domestic recycling, whether you bring your scrap to our yard or need a roll-off container and/or <a title="Recycling & Material Handling Equipment" className="page-scroll" href="#equipment">equipment</a>, we can help!</p>
                            <h2>
                                What Can I Recycle?<br />
                                <strong />
                            </h2>
                            <p>
                                <strong>
                                    Scrap Metal Products
                                </strong>
                            </p>
                            <ul>
                                <li>Aluminum cans</li>
                                <li>Aluminum Siding</li>
                                <li>Plastic and glass bottles</li>
                                <li>B-B-Q’s</li>
                                <li>
                                    Bicycles
                                </li>
                                <li>Aluminum Sheds</li>
                                <li>Wheels/Rims</li>
                                <li>Aluminum Ladders</li>
                                <li>Bed Frames</li>
                                <li>Trampolines</li>
                                <li>Window Frames</li>
                                <li>Metal Fencing</li>
                                <li>Metal Shelves &amp; Racks</li>
                                <li>Wiring</li>
                                <li>Metal Filing Cabinets</li>
                                <li>Insulated Copper Wire</li>
                                <li>Copper  Tubing</li>
                            </ul>
                            <p>
                                <strong>
                                    Household Appliances
                                </strong>
                            </p>
                            <ul>
                                <li>Refrigerators</li>
                                <li>
                                    Stoves
                                    &amp; Freezers
                                </li>
                                <li>
                                    Washers
                                    &amp; Dryers
                                </li>
                                <li>Water heaters</li>
                                <li>Small electrical appliances</li>
                                <li>Air conditioners</li>
                                <li>Dishwashers</li>
                                <li>Cast Iron Tubs</li>
                            </ul>
                            <p>
                                <strong>
                                    Automobiles &amp; Equipment
                                </strong>
                            </p>
                            <ul>
                                <li>Cars</li>
                                <li>Boat Trailers</li>
                                <li>Truck Beds</li>
                                <li>Motor Cycles</li>
                                <li>Motor Cycle Trailers</li>
                                <li>Radiators</li>
                                <li>Batteries</li>
                                <li>Copper Radiators</li>
                                <li>356 Alum Wheels</li>
                                <li>Wheel Weights</li>
                            </ul>
                            <p>
                                <strong>Can I Recycle ?</strong><br />
                                When most people hear the word “recycle” they think of paper goods, plastics and aluminum cans. However, there is another industry in the recycling world called Scrap Metal Recycling, which process more than 125 Million Tons of recycling each year! Scrap Metal Recycling is the most popular form of recycling in the United States.  Steel and aluminum can be infinitely recycled.  Scrap metal consists of ferrous metals or non-ferrous metals. Large items such as cars, dishwashers, washing machines, stoves, and refrigerators are generally created from metal and are the best items to recycle. When you recycle Scrap Metal, you re-use the material and save our natural resources.
                            </p>
                            <p>In addition to providing benefits for the environment, however, Scrap Metal Recycling is also a far more cost effective solution to creating metals than actually producing new metal products.  As a result, recycling metals is one of the largest industries in the world today.</p>
                            <p>
                                <strong>Does Sunshine Recycling have a Fundraiser program?</strong><br />
                                Sunshine Recycling has assisted the community with fundraising for any and all occasions. <a title="Call or Contact Sunshine Recycling" className="page-scroll" href="#contact">Call to set up an appointment</a> to discuss your need.  You’ll be glad you did!
                            </p>
                            <p>If you have other questions about what can and/or cannot be recycled, give us a call at 1-803-531-4408 or use our on-line <a className="page-scroll" href="#contact"><em><strong>contact form</strong></em></a></p>
                        </div>{/* .entry-content */}
                    </div>
                    <div className="col-md-4 col-md-offset-1">
                        <div className="embed-responsive embed-responsive-16by9">
                            <img id="untitled-region-7" className="img-responsive editable products-image" src="img/domestic-services.jpg" />
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </>)
}

export default DomesticServices