import React, { Component } from "react";

const BottomNav = (props) => {
    return (<>
        <a href="#top" className="topHome page-scroll"><i className="fa fa-chevron-up fa-2x"></i></a>
        <a href="#bin-service" className="bin-service page-scroll">
            <img className="bin-service-image" src="../img/bin-service-btn.png" />
        </a>
    </>);
}

export default BottomNav;