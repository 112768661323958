import React from 'react'

function BinServices() {
    return (<>
        {/* bin-service */}
        <section id="bin-service" style={{ background: 'rgba(255, 255, 255, 1.0)', borderTop: '1px solid #ddd' }}>
            <div className="container wow fadeIn">
                <div className="row">
                    <div className="col-md-6">
                        <h2 className="editable section-title" id="untitled-region-42">We are here for you</h2>
                        <div className="editable" style={{ textAlign: 'justify' }}>
                            <p>For your convenience, Sunshine Recycling is equipped to drop off containers to your door for later collection or ongoing exchanges. This service includes the availability of numerous sized scrap metal containers and specialized vehicles.</p>
                        </div>
                    </div>
                    <div className="col-md-5 col-md-offset-1">
                        <h2 className="editable section-title" id="untitled-region-46">Bin Service</h2>
                        <p className="editable" id="untitled-region-47">
                            Fill out the form below and one of our management team will contact you.
                            Minimum quantities and service area restrictions apply.
                        </p>
                        <form method="post" action="../ajax/sendBinServiceEmail.aspx" fromemail="jrich@sunshinerecycle.com" fromname="Sunshine Recycle" id="bin-service-form" role="form">
                            <div className="message col-xs-12">
                                <p className="bin-email-loading"><img src="img/loading.gif" alt="" />&nbsp;&nbsp;&nbsp;Sending...</p>
                                <p className="bin-email-success"><i className="icon icon-icon-check-alt2" /> Your message has successfully been sent.</p>
                                <p className="bin-email-failed"><i className="icon icon-icon-close-alt2" /> Something went wrong!</p>
                            </div>
                            <div className="control-group">
                                <div className="form-group floating-label-form-group controls">
                                    <label htmlFor="name" className="sr-only control-label">Your Name</label>
                                    <input type="text" className="form-control input-lg" placeholder="Your Name" id="contact-bin-name" required data-validation-required-message="Please enter name" />
                                    <span className="help-block text-danger" />
                                </div>
                            </div>
                            <div className="control-group">
                                <div className="form-group floating-label-form-group controls">
                                    <label htmlFor="email" className="sr-only control-label">Your Email</label>
                                    <input type="email" className="form-control input-lg" placeholder="Your Email" id="contact-bin-email" required data-validation-required-message="Please enter email" />
                                    <span className="help-block text-danger" />
                                </div>
                            </div>
                            <div className="control-group">
                                <div className="form-group floating-label-form-group controls">
                                    <label htmlFor="phone" className="sr-only control-label">Your Phone</label>
                                    <input type="tel" className="form-control input-lg" placeholder="Your Phone" id="contact-bin-phone" required data-validation-required-message="Please enter phone number" />
                                    <span className="help-block text-danger" />
                                </div>
                            </div>
                            <div className="control-group">
                                <div className="form-group floating-label-form-group controls">
                                    <label htmlFor="message" className="sr-only control-label">Location (city &amp; state)</label>
                                    <textarea rows={2} className="form-control input-lg" placeholder="Location (city & state)" id="contact-bin-location" required data-validation-required-message="Please enter a message." aria-invalid="false" defaultValue={""} />
                                    <span className="help-block text-danger" />
                                </div>
                            </div>
                            <div className="control-group">
                                <div className="form-group floating-label-form-group controls">
                                    <label htmlFor="message" className="sr-only control-label">List the style and type of your items</label>
                                    <textarea rows={2} className="form-control input-lg" placeholder="List the style and type of your items" id="contact-bin-message" required data-validation-required-message="Please enter a message." aria-invalid="false" defaultValue={""} />
                                    <span className="help-block text-danger" />
                                </div>
                            </div>
                            <div id="success" />
                            <button type="submit" className="btn border-button-black" id="submit">Send</button>
                        </form>
                    </div>
                </div>
            </div>
        </section>

    </>)
}

export default BinServices