import React from 'react'
import IndustrialPhotosData from "./IndustrialPhotosData";

function IndustrialPhotos(props) {
    return (<>
        {/* Industrial Photos */}
        <section id="industrial-photos" className="action-parallax section-panel" style={{ background: 'rgba(245, 245, 245, 1.0)', borderTop: '1px solid #ddd' }}>
            <div className="container-fluid text-center wow fadeIn">
                <h2 className="editable section-title col-md-offset-1" id="untitled-region-5">Gallery<span className="section-title-sub">&nbsp;<i className="fa-solid fa-caret-right" />&nbsp;Industrial Photos</span></h2>
                <div style={{ height: '100px' }} />
                <div className="row">
                    <div className="col-md-10 col-md-offset-1" style={{ textAlign: 'left' }}>
                        <div className="entry-content">
                            <div className="portfolio wow fadeIn">
                                <ul id="projects-portfolio-slider" className="editable-gallery" data-links="false" data-thumbnail-width={400} data-thumbnail-height={300} data-thumbnail-resize="best-fit">
                                    <IndustrialPhotosData industrialPhotos={props.industrialPhotosList.industrialPhotos} />
                                </ul>
                            </div>
                        </div>{/* .entry-content */}
                    </div>
                </div>
            </div>
        </section>

    </>)
}

export default IndustrialPhotos