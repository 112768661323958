import React from 'react'

function Projects() {
    return (<>
        {/* Project Page */}
        <section id="project-page" className="action-parallax section-panel" style={{ background: 'rgba(255, 255, 255, 0.85)', borderTop: '1px solid #ddd' }}>
            <div className="container-fluid text-center wow fadeIn">
                <h2 className="editable section-title col-md-offset-1" id="untitled-region-5">Aggregate<span className="section-title-sub">&nbsp;<i className="fa-solid fa-caret-right" />&nbsp;Project Page</span></h2>
                <div className="row">
                    <div className="col-md-6 col-md-offset-1" style={{ textAlign: 'justify' }}>
                        <div className="entry-content">
                            <p>
                                <strong>Southern Sales and Marketing Group – Orangeburg SC</strong><br />
                                120,000 tons of concrete from demolition was crushed and screened on-site. Three different products were produced at the same time, CR 14, 57 Stone and 4 MOD.  Products were used in several different applications.
                            </p>
                            <p>
                                <strong>Coastal Lumber – Walterboro SC</strong><br />
                                25,000 tons of concrete from demolition was crushed and screened on-site to CR 14 – 1.5” minus and re-used as sub-base material for the access roads and parking areas. Product was also sold on site to area contractors
                            </p>
                            <p>
                                <strong>W&amp;B Trucking – Walterboro SC</strong><br />
                                12,000 tons of concrete material was crushed and screened on-site for customers use.
                            </p>
                            <p>
                                <strong>Double L Farm – Bowman SC</strong><br />
                                1,000 tons of concrete was picked up from the site and transported to our Aggregate yard.
                            </p>
                            <p>
                                <strong>Private Property – Walterboro SC</strong><br />
                                10,000 tons of concrete dumped from paving contractor on private land. Paving Contractor removed the concrete from the I-95. The concrete was moved off site where it was crushed and screened to CR 14 for road base.
                            </p>
                        </div>{/* .entry-content */}
                    </div>
                    <div className="col-md-4 col-md-offset-1">
                        <img id="untitled-region-7" className="img-responsive editable" src="img/industrial/industrial-22.jpg" />
                    </div>
                </div>
            </div>
        </section>

    </>)
}

export default Projects