import React from 'react'

function Faq() {
    return (<>
        {/* FAQ */}
        <section id="faq" className="action-parallax section-panel" style={{ background: 'rgba(255, 255, 255, 0.85)', borderTop: '1px solid #ddd' }}>
            <div className="container-fluid text-center wow fadeIn">
                <h2 className="editable section-title col-md-offset-1" id="untitled-region-5">Frequently Asked Questions</h2>
                <div className="row">
                    <div className="col-md-6 col-md-offset-1" style={{ textAlign: 'justify' }}>
                        <div className="entry-content">
                            <h2>Scrap Metal FAQ’s</h2>
                            <p>
                                <strong>What is a Scrap Yard?</strong><br />
                                Scrap Metal (which originates from homes, businesses, schools, etc…) should be properly disposed of at a certified recycling Scrap Yard.  Such Scrap Metals should NOT be put in our landfills, but rather a place where they can be processed for later melting into new products. Sunshine Recycling is a Metal Scrap yard that will pay cash for your Scrap Metal.  We understand that you may not have the ability to get your Scrap Metal to the yard – many people just don’t have the resources to get large Scrap Metal to yards.  <a title="Call or Contact Sunshine Recycling" className="page-scroll" href="#contact">Call us today</a> and we can assist you with pick-up and delivery of all your scrap metal. A reputable Metal Scrap Yard like Sunshine Recycling will <a title="Metals Purchased by Sunshine Recycling" className="page-scroll" href="#metals-purchased">pay by weight and metal type</a>.  Every customer will receive a “weight ticket” that has the time, date and weight stamped on it, calculated by our Certified Toledo Digital Scale
                            </p>
                            <p>
                                <strong>What is Special about your Scrap Yard?</strong><br />
                                Learn more about the Sunshine Difference by visiting our <a title="About Us" className="page-scroll" href="#about" target="_blank">About Us</a> page
                            </p>
                            <p>
                                <strong>What are your hours?</strong><br />
                                Sunshine Recycling is open Monday-Friday from 9AM-5PM and Saturday from 9AM-5PM
                            </p>
                            <p>
                                <strong>What is the current price for a specific type of metal?</strong><br />
                                Prices vary with the market. <a title="Call or Contact Sunshine Recycling" className="page-scroll" href="#contact">Give us a call</a> for the latest pricing.
                            </p>
                            <p>
                                <strong>Do you accept computers, monitors and electronics?</strong><br />
                                Sunshine Recycling does accept all electronics.
                            </p>
                            <p>
                                <strong>What types of metal do you accept?</strong><br />
                                Visit our <a title="Materials Purchased" className="page-scroll" href="#metals-purchased" target="_blank">accepted materials</a> page to see a complete list of materials that we purchase.
                            </p>
                            <p>
                                <strong>Do you have a pick-up service?</strong><br />
                                Pick up service is available.  Please <a title="Call or Contact Sunshine Recycling" className="page-scroll" href="#contact">call for details</a>.
                            </p>
                            <p>
                                <strong>Do you accept car batteries?</strong><br />
                                We do accept car batteries.
                            </p>
                            <p>
                                <strong>How much do you pay for scrap metal?</strong><br />
                                Prices vary with the market. <a title="Call or Contact Sunshine Recycling" className="page-scroll" href="#contact">Give us a call</a> for the latest pricing.
                            </p>
                            <p>
                                <strong>Can you leave a collection bin at my home or business?</strong><br />
                                Depending on the volume, type of scrap and your location, we can leave a <a title="Roll Off Bins & Other Scrap Metal Collection Equipment" className="page-scroll" href="#equipmnet">roll-off bin</a> at your home or place of business.  If you have a large volume of scrap that needs collection on a more frequent basis we can also arrange for regularly scheduled pick-up.
                            </p>
                            <p>
                                <strong>What makes Sunshine Recycling different from other recycling companies?</strong><br />
                                Visit our <a title="About Us" className="page-scroll" href="#about">About Us</a> page and learn more about the Sunshine Difference.<br />
                                <strong />
                            </p>
                            <p>
                                <strong>How is my company compensated for our metal scrap?</strong><br />
                                Sunshine Recycling pays according to the American Metal Markets monthly report. We use a State Certified Toledo Digital Scale. When your scrap metal arrives at Sunshine Recycling, the truck is weighed. The driver unloads the metal at our processing center, where it is graded. The empty truck is then weighed out.  Sunshine Recycling sends you written documentation that details the weight, time and date of your scrap metal when it gets processed. Sunshine Recycling sends out settlements on the 15th of each subsequent month unless you have some specialty requirements for when you would like to be paid.
                            </p>
                        </div>{/* .entry-content */}
                    </div>
                    <div className="col-md-4 col-md-offset-1">
                        <img id="untitled-region-7" className="img-responsive editable" src="img/industrial/industrial-22.jpg" />
                    </div>
                </div>
            </div>
        </section>

    </>)
}

export default Faq