import React from 'react'

function about(props) {
    return (<>
        <section id="about" className="action-parallax section-panel" style={{ "background": "rgba(255, 255, 255, 0.85)" }} >
            <div className="container-fluid text-center wow fadeIn">
                <h2 className="editable section-title col-md-offset-1" id="untitled-region-5">About Us</h2>
                <div className="row">
                    <div className="col-md-8 col-md-offset-1 lead" style={{ "textAlign": "justify" }}>


                        <p>
                            <strong>Sunshine Recycling</strong> prides itself on bringing innovation to the Metals Recovery
                            industry. In 1996 the Founder and CEO Joe Rich started what has become one of America&#8217;s most
                            State of the Art processing and recycling facilities.
                        </p>
                        <p>
                            The following innovations were created at Sunshine Recycling and have revolutionized the Metals
                            Recovery Industry:
                        </p>
                        <ul>
                            <li>
                                Drive Thru <a title="Non Ferrous Metal Recycling at Sunshine Recycling" className="page-scroll"
                                    href="#non-ferrous-metals">Non-Ferrous Warehouse</a>
                                <ul>
                                    <li>
                                        Every Platform Scale in the Drive Thru Non-Ferrous Warehouse is supported by a
                                        blacklight system to screen for the UV “Invisigard” Paint developed by Sunshine
                                        Recycling and used by businesses and the community to deter theft.
                                    </li>
                                </ul>
                            </li>
                            <li>
                                Six – McDonalds Services, Inc. High-Density Downstroke Balers
                                <ul>
                                    <li>
                                        Modified by Sunshine Recycling to accommodate higher pressure and higher density bales.
                                    </li>
                                </ul>
                            </li>
                            <li>
                                NPDES <em>(National Pollutant Discharge Elimination System)</em> Industrial Storm Water Drainage
                                Facility with ELEVEN Custom-Designed Oil Water Separators onsite at all runoff points.
                            </li>
                            <li>
                                2010 Terex Fuchs MHL 360D “Hybrid” – Labounty 3000 Shear attached. The <strong>ONLY</strong> one
                                in the world!
                            </li>
                            <li>
                                Onsite and <a title="Mobile Car Crushing at Sunshine Recycling" className="page-scroll"
                                    href="#mobile-car-crushing">Mobile Car Crushing</a> system that separates the copper,
                                aluminum and zinc from the steel for greater Sustainability and a higher profit for the
                                customer.
                            </li>
                        </ul>
                        <p>
                            Combining the expertise of our knowledgeable staff with the latest state-of-the-art technologies,
                            Sunshine Recycling delivers a full spectrum of integrated metals recovery and recycling services.
                        </p>
                        <p><em>Sunshine Recycling is setting the Standard for Sustainable Recycling Solutions.</em></p>

                    </div>
                    <div className="col-md-2 col-md-offset-1">
                        <div>
                            <a className="page-scroll" href="#plant">
                                <img id="untitled-region-7" className="img-responsive editable about-image products-image"
                                    src="img/state-of-the-art-facility.jpg"
                                    style={{ " borderTopLeftRadius": "10px", "borderTopRightRadius": "10px" }} />
                            </a>
                        </div>
                        <div>
                            <a className="page-scroll" href="#mobile-car-crushing">
                                <img id="untitled-region-7" className="img-responsive editable about-image products-image"
                                    src="img/mobile-crushing.jpg" />
                            </a>
                        </div>
                        <div>
                            <a className="page-scroll" href="#plant">
                                <img id="untitled-region-7" className="img-responsive editable about-image products-image"
                                    src="img/advanced-recycling.jpg" />
                            </a>
                        </div>
                        <div>
                            <a className="page-scroll" href="#videos">
                                <img id="untitled-region-7" className="img-responsive editable about-image products-image"
                                    src="img/watch-videos.jpg"
                                    style={{ "borderBottomLeftRadius": "10px", "borderBottomRightRadius": "10px" }} />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>)
}

export default about