import React from 'react'

function Plant() {
    return (<>
        {/* Plant */}
        <section id="plant" className="section-panel" style={{ color: '#fff !Important', background: 'rgba(0, 0, 0, 1.00)', borderTop: '1px solid #ddd' }}>
            <div className="container-fluid text-center wow fadeIn">
                <h2 className="editable section-title col-md-offset-1" id="untitled-region-5">Recycling<span className="section-title-sub">&nbsp;<i className="fa-solid fa-caret-right" />&nbsp;Sunshine Recycling Plant</span></h2>
                <div className="row">
                    <div className="col-md-6 col-md-offset-1" style={{ textAlign: 'justify' }}>
                        <div className="entry-content textWhite">
                            <p><strong>Experts in Recycling</strong></p>
                            <p>We are a Certified NPDES <em>(National Pollutant Discharge Elimination System)</em> Industrial Storm water drain-off facility. Sunshine Recycling currently has ELEVEN oil-water separators on our Plant protecting our community from oil and other harmful substances associated with scrap metal.</p>
                            <p>You can have the satisfaction that your scrap is being processed at a PERMITTED NPDES Industrial Storm Water drainage facility. Your scrap is recycled, processed and shipped from Sunshine Recycling to a steel mill for consumption.</p>
                            <p><em>Sunshine Recycling is able to offer certified destruction of your obsolete or unwanted equipment, complete with a Destruction Certificate.</em></p>
                            <p>
                                <strong>Therman Toledo Digital Scales</strong><br />
                                Onsite digital readout on the outside of Scale House<strong /><br />
                                <strong />
                            </p>
                            <p>
                                <strong>Environmental</strong><br />
                                ELEVEN oil water separators on site linked to private drainage infrastructure. Water is processed a minimum of two times to ensure environmental compliance so that all runoff water meets DHEC standards. <strong />
                            </p>
                            <p>
                                <strong>Non-Ferrous Collection site</strong><br />
                                <em> South Carolina’s FIRST state-of-the-art drive-thru warehouse</em>
                            </p>
                            <ul>
                                <li>Accommodate two lanes of traffic</li>
                                <li>Four Toledo Digital Platform Scales inside the warehouse</li>
                                <li>
                                    Two Toledo Digital Platfrom Scales outside the warehouse for aluminum can purchases<br />
                                    <em>** Every platform scale is supported by a blacklight system to screen for the UV “Invisigard” paint developed by Sunshine Recycling used by business and the community to deter theft.</em>
                                </li>
                                <li>Warehouse has approximately 3600’ of dry storage for finished baled aluminum, copper, brass and other non-ferrous products</li>
                                <li>Equipped with safety shower</li>
                                <li>Video cameras inside and out to record tag numbers and customer transactions supported by a DVR Recording System<strong /></li>
                                <li>
                                    <strong>Two Densi Can Flattners</strong><br />
                                    One in ground conveyor system – DAC 3000 (flatten 3000 lbs per hour)<br />
                                    One above ground Super Chopper Densi Can DAC 1200 for processing aluminum cans<strong />
                                </li>
                                <li>
                                    <strong>Five – McIntyre Affordable Shears</strong><br />
                                    Non-Ferrous processor Shears vary from 12” – 24” jaw sizes.<strong />
                                </li>
                                <li>
                                    <strong>Six – McDonalds Services Inc High Density Downstroke Balers</strong><br />
                                    Modified by Sunshine Recycling to accommodate even higher pressure, higher density bales.
                                </li>
                            </ul>
                            <p>
                                <strong>Rail Service</strong><br />
                                Sunshine Recycling has two rail sites. One site is 1382’ and the second site is 386’ to facilitate loading and unloading rail cars.
                            </p>
                            <p>
                                <strong>Vehicle Recycling</strong><br />
                                Sunshine Recycling has a division with staff dedicated to vehicle recycling. Simply drive up on our State Certified Scale. Follow the signs to the one lane that will direct you to the vehicle processing area. Our staff will come in with a forklift and air tools to remove the tires and rims if you wish to keep them. If not, we will make you an offer for the tires and rims. Our staff will unload the vehicle for you, after which you drive back over our State Certified Scale. Once the weight is determined, Sunshine Recycling will print out a ticket that gives you the time, date and weight for your vehicle. You will be paid immediately for your vehicle after the weight has been verified. Click <a href="http://www.sunshineautosalvage.com/" target="_blank"><strong>here</strong></a> for more about our vehicle recycling services.<br />
                                <strong />
                            </p>
                            <p>
                                <strong>Ferrous Metals</strong><br />
                                Ferrous is a latin term used to indicate the presence of iron. Sunshine Recycling has a division devoted to processing ferrous metals. Click <a className="page-scroll" href="#ferrous-metals"><strong>here</strong></a> to learn more about ferrous metals.
                            </p>
                        </div>{/* .entry-content */}
                    </div>
                    <div className="col-md-4 col-md-offset-1">
                        <div className="embed-responsive embed-responsive-16by9">
                            <img id="untitled-region-7" className="img-responsive editable products-image" src="img/plant.jpg" />
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </>)
}

export default Plant