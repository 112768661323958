import React from 'react'

function SiteMaintenance() {
    return (<>
        {/* Site Maintenance/Demolition Services */}
        <section id="demolition-service" className="action-parallax section-panel" style={{ background: 'rgba(255, 255, 255, 0.85)', borderTop: '1px solid #ddd' }}>
            <div className="container-fluid text-center wow fadeIn">
                <h2 className="editable section-title col-md-offset-1" id="untitled-region-5">Services<span className="section-title-sub">&nbsp;<i className="fa-solid fa-caret-right" />&nbsp;Site Maintenance Demolition Services</span></h2>
                <div className="row">
                    <div className="col-md-6 col-md-offset-1" style={{ textAlign: 'justify' }}>
                        <div className="entry-content">
                            <p>Sunshine Recycling has the capacity to manage logistics, making the <a title="Sunshine Recycling Metal Collection" className="page-scroll" href="#domestic-services">collection of metals </a>or concrete anywhere in the Southeast nearly effortless.</p>
                            <p>We offer an extensive range of <a title="Metal Recycling Equipment at Sunshine Recycling" className="page-scroll" href="#equipment">mobile equipment</a> including material handlers, balers, shears, and magnets. Sunshine Recycling is always ready to clear property across the state and beyond.</p>
                            <p>By bailing or compacting your scrap metals, Sunshine Recycling can load up to four times as much onto a truck or trailer, thus reducing the on-road costs and therefore putting money back in your pocket.</p>
                            <p>Sunshine Recycling provides a complete range of site demolition and dismantling services. You can rest assured that your projects – regardless of size – will receive prompt and professional service.</p>
                            <p><strong>Sunshine Recycling offers many options for Onsite Maintenance</strong></p>
                            <ul>
                                <li><strong>Total Demolition</strong> in which we can completely bring down a structure or a series of several structures. Whether your need is to remove a multi-story building or a single water tower, Sunshine Recycling can demolish structures with the highest degree of precision.</li>
                                <li><strong>Selective Demolition</strong> of a structure and/or equipment.  We have the expertise and equipment that assures we will not cause damage to other areas of your site during a Selective Demolition job. Your options are limitless! Sunshine Recycling can remove interior or exterior equipment, ceilings, floors or walls. Selective Demolition also includes stripping out material or equipment that needs to be removed for you to re-configure floor space.</li>
                            </ul>
                            <p><strong>Emergency Response</strong> – Sunshine Recycling takes pride in the fact that we can respond to many disasters, ranging from hurricanes to crime scenes of arson. We can also accommodate emergencies requiring various demolition services to keep your company functioning in the case of an internal mishap.</p>
                            <p><a className="page-scroll" href="#contact"><strong>Call today for a Free Estimate!</strong></a></p>
                            <p><img className="width95px" src="../img/leedslogo.png" style={{ width: '95px !important' }} alt="" /><strong>&nbsp;LEED</strong><strong> – SUNSHINE RECYCLING is qualified to assist with projects seeking LEED points.  We have extensive construction experience and can<strong> coordinate the recycling and disposal of C&amp;D materials for your project.</strong></strong></p>
                        </div>{/* .entry-content */}
                    </div>
                    <div className="col-md-4 col-md-offset-1">
                        <div className="embed-responsive embed-responsive-16by9">
                            <img id="untitled-region-7" className="img-responsive editable products-image" src="img/demolition.jpg" />
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </>)
}

export default SiteMaintenance