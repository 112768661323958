import React from 'react'

function RollOfContainers() {
    return (<>
        {/* Roll Off Containers */}
        <section id="roll-off-containers" className="action-parallax section-panel" style={{ background: 'rgba(255, 255, 255, 0.85)', borderTop: '1px solid #ddd' }}>
            <div className="container-fluid text-center wow fadeIn">
                <h2 className="editable section-title col-md-offset-1" id="untitled-region-5">Recycling<span className="section-title-sub">&nbsp;<i className="fa-solid fa-caret-right" />&nbsp;Roll Off Containers</span></h2>
                <div className="row">
                    <div className="col-md-6 col-md-offset-1" style={{ textAlign: 'justify' }}>
                        <div className="entry-content">
                            <p>For your convenience, Sunshine Recycling is equipped to drop off containers to your door for later collection or ongoing exchanges. This service includes the availability of numerous sized scrap metal containers and specialized vehicles. Scrap metals are collected from a vast range of industry, commercial, and domestic clientele, FREE OF CHARGE (conditions apply) across the State.</p>
                            <p>We offer full scrap salvage consultation and appraisal, free of charge, to produce the most cost effective and space conscious recycling solutions to meet your requirements. This will ensure that all of your recycling requirements can be catered for by a ONE-STOP approach.</p>
                            <p><strong>Roll Off Containers Service Equipment</strong></p>
                            <ul>
                                <li>Fleet of over 100 Roll-Off Containers from Custom designed Five Yard miniatures to large capacity 80 yard containers.</li>
                                <li>Benlee Super Mini Roll-Off Trailer.  Spotting the 5, 10, 20, 30, 40 yard Roll-Off containers. Provides for added weight capacity</li>
                                <li>Three – Peterbilt Tandem Axle Roll Off</li>
                            </ul>
                        </div>{/* .entry-content */}
                    </div>
                    <div className="col-md-4 col-md-offset-1">
                        <div className="embed-responsive embed-responsive-16by9">
                            <img id="untitled-region-7" className="img-responsive editable products-image" src="img/rolloff-dumpster-container-rectangle-green-600x400.png" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>)
}

export default RollOfContainers