import React from "react";

const NonFerrousMetals = (props) => {
    return (<>
        {/* Non Ferrous Metals */}
        <section id="non-ferrous-metals" className="action-parallax section-panel" style={{ background: 'rgba(255, 255, 255, 0.85)', borderTop: '1px solid #ddd' }}>
            <div className="container-fluid text-center wow fadeIn">
                <h2 className="editable section-title col-md-offset-1" id="untitled-region-5">Recycling<span className="section-title-sub">&nbsp;<i className="fa-solid fa-caret-right" />&nbsp;Non Ferrous Metals</span></h2>
                <div className="row">
                    <div className="col-md-6 col-md-offset-1" style={{ textAlign: 'justify' }}>
                        <div className="entry-content">
                            <p>Below is a list of non-ferrous metals that we purchase and recycle:</p>
                            <ul>
                                <li>Aluminum Cans</li>
                                <li>Aluminum Cast</li>
                                <li>Aluminum Extrusion</li>
                                <li>Aluminum (Copper) Radiators</li>
                                <li>Aluminum Wheels</li>
                                <li>Aluminum Domestic (Mixed)</li>
                                <li>Batteries</li>
                                <li>Brass</li>
                                <li>Catalytic Convertors</li>
                                <li>Copper Wire</li>
                                <li>Copper No.1 – clean pipe, sheet, solids</li>
                                <li>Copper No.2 – dirty pipe, wire, solids</li>
                                <li>Copper (Brass) Radiators</li>
                                <li>Copper Domestic (Mixed)</li>
                                <li>Electric Motors</li>
                                <li>Gun Metal</li>
                                <li>Lead</li>
                                <li>Stainless Steel</li>
                                <li>Zinc</li>
                                <li>Copper Wire Insulated (electrical cables)</li>
                                <li>Aluminum Cuttings</li>
                            </ul>
                        </div>{/* .entry-content */}
                    </div>
                    <div className="col-md-4 col-md-offset-1">
                        <div className="embed-responsive embed-responsive-16by9">
                            <img id="untitled-region-7" className="img-responsive editable products-image" src="img/non-ferrous-metals.jpg" />
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </>);
}

export default NonFerrousMetals;