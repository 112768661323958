import React from "react";

const SiteFooter = (props) => {
    return (<>
        <section id="footer" className="parallax site-wrapper site-wrapper-bottom" data-speed="4" data-type="background">
            <a id="groundVideo" className="player" data-property="{videoURL:'https://www.youtube.com/watch?v=Ag6SyKCw-i4', containment:'.site-wrapper-bottom', autoPlay:true, loop:true, mute:true, quality:'highres', ratio: '16/9', opacity:1, showControls: false, showYTLogo:false, vol:25}"></a>
            <div className="site-wrapper-inner">
                <div className="cover-container inner2">
                    <div className="cover">
                        <h2 className="wow flash" data-wow-iteration="999" data-wow-duration="2s"><a page-scroll href="#top"><i className="icon fas fa-angle-up"></i></a>&nbsp;</h2>
                        <div className="row text-center">
                            <div className="col-xs-12">
                                <h3 className="editable" id="untitled-region-48" style={{ "color": "#fff" }}>
                                    {props.companyInfo.companyName}<br /> {props.companyInfo.companyAddress}<br /> {props.companyInfo.companyCity}, {props.companyInfo.companyState} {props.companyInfo.companyZip}<br /> {props.companyInfo.companyPhone}<br /> ©2022
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>);
}

export default SiteFooter;