import React from 'react'

function ProhibitedMaterials() {
    return (<>
        {/* Prohibited Materials */}
        <section id="prohibited-materials" className="action-parallax section-panel" style={{ background: 'rgba(255, 255, 255, 0.85)', borderTop: '1px solid #ddd' }}>
            <div className="container-fluid text-center wow fadeIn">
                <h2 className="editable section-title col-md-offset-1" id="untitled-region-5">Recycling<span className="section-title-sub">&nbsp;<i className="fa-solid fa-caret-right" />&nbsp;Prohibited Material</span></h2>
                <div className="row">
                    <div className="col-md-6 col-md-offset-1" style={{ textAlign: 'justify' }}>
                        <div className="entry-content">
                            <ul>
                                <li>Soils</li>
                                <li>Tires</li>
                                <li>Timber</li>
                                <li>Poisons</li>
                                <li>Rubbish</li>
                                <li>Asbestos</li>
                                <li>Corrosives</li>
                                <li>Explosives</li>
                                <li>Gas Bottles</li>
                                <li>LPG Tanks</li>
                                <li>Liquid Waste</li>
                                <li>Pressure Vessels</li>
                                <li>Flammable Liquids</li>
                                <li>Refrigerant Gases</li>
                                <li>Hazardous Materials</li>
                                <li>Radio Active Materials</li>
                                <li>And “anything” Non-Metallic</li>
                            </ul>
                        </div>{/* .entry-content */}
                    </div>
                    <div className="col-md-4 col-md-offset-1">
                        <div className="embed-responsive embed-responsive-16by9">
                            <img id="untitled-region-7" className="img-responsive editable products-image" src="img/Prohibited-Materials.png" />
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </>)
}

export default ProhibitedMaterials