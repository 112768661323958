import React from 'react'

function MetalsPurchased() {
    return (<>
        {/* Metals Purchased */}
        <section id="metals-purchased" className="action-parallax section-panel" style={{ background: 'rgba(255, 255, 255, 0.85)', borderTop: '1px solid #ddd' }}>
            <div className="container-fluid text-center wow fadeIn">
                <h2 className="editable section-title col-md-offset-1" id="untitled-region-5">Recycling<span className="section-title-sub">&nbsp;<i className="fa-solid fa-caret-right" />&nbsp;Metals Purchased</span></h2>
                <div className="row">
                    <div className="col-md-7 col-md-offset-1" style={{ textAlign: 'justify' }}>
                        <div className="entry-content">
                            <p>Sunshine is pleased to purchase the following metals. For rates, please <a className="page-scroll" href="#contact"><strong>contact us</strong></a> today.</p>
                            <ul>
                                <li>Bare/Bright wire only</li>
                                <li>#1 Copper</li>
                                <li>#2 Copper</li>
                                <li>Light Copper</li>
                                <li>Insulated #1 Copper</li>
                                <li>Insulated #2 Copper</li>
                            </ul>
                            <p><strong>Brass</strong></p>
                            <ul>
                                <li>Hard Brass</li>
                                <li>Red Brass</li>
                                <li>Yellow Brass</li>
                                <li>Plumber’s Brass</li>
                            </ul>
                            <p><strong>Radiator/Coils/Lead</strong></p>
                            <ul>
                                <li>Auto Radiators (Brass &amp; Copper)</li>
                                <li>Irony Auto Radiators (Brass &amp; Copper)</li>
                                <li>A/C Radiators (Alum &amp; Copper)</li>
                                <li>Irony A/C Radiators (Alum &amp; Copper)</li>
                                <li>All Aluminum Radiators (listed as OS)</li>
                                <li>Aluminum Turnings</li>
                                <li>Irony Alum Radiators (listed as LIA)</li>
                                <li>Heater Coils</li>
                                <li>Lead</li>
                                <li>Irony Lead</li>
                            </ul>
                            <p><strong>Aluminum</strong></p>
                            <ul>
                                <li>Cans</li>
                                <li>Extrusion/Clean Aluminum</li>
                                <li>MLC Aluminum</li>
                                <li>New Sheet/Clean Aluminum</li>
                                <li>Painted Siding/Clean Aluminum</li>
                                <li>OS (Old Sheet)/Clean Aluminum</li>
                                <li>Cast/Clean Aluminum</li>
                                <li>Light Irony/Aluminum</li>
                                <li>Heavy Iron/Aluminum</li>
                            </ul>
                            <p><strong>Stainless Steel</strong></p>
                            <ul>
                                <li>316 – Clean Stainless Steel</li>
                                <li>304 – Clean Stainless Steel</li>
                                <li>304 – Irony Stainless Steel</li>
                            </ul>
                            <p><strong>Ferrous Metals</strong></p>
                            <ul>
                                <li>Appliances, Tin, Misc. Shreadables</li>
                                <li>P&amp;S (Plate &amp; Structural)</li>
                                <li>Prep #1 Steel (cut 3 ft x 18 in or smaller)</li>
                                <li>Prep #2 Steel (cut 3 ft x 18 in or smaller)</li>
                                <li>Unprepared #1 Steel</li>
                                <li>Unprepared #2 Steel</li>
                                <li>Whole Car Bodies w/ Motor or Trans (&amp; Motor Parts)</li>
                                <li>Busheling</li>
                            </ul>
                            <p><em>All materials that have to be cleaned will be down graded to the least valuable item in its category, unless specified by management</em></p>
                            <p><strong>Automotive</strong></p>
                            <ul>
                                <li>Catalytic Converter (each)</li>
                                <li>Transmissions (all aluminum only)</li>
                            </ul>
                            <p><strong>Electronics</strong></p>
                            <ul>
                                <li>Cell phones</li>
                                <li>E-scrap (computer keyboard, towers, mouse)</li>
                            </ul>
                            <p><strong>Bronze</strong></p>
                            <ul>
                                <li>Electric Motors</li>
                                <li>Mag</li>
                                <li>Brass Bronze</li>
                                <li>Brass Turnings</li>
                                <li>Heavy Torchable</li>
                                <li>Aluminum Rims</li>
                            </ul>
                        </div>{/* .entry-content */}
                    </div>
                    <div className="col-md-3 col-md-offset-1">
                        <img id="untitled-region-7" className="img-responsive editable products-image" src="img/circleOne-300x300.png" />
                    </div>
                </div>
            </div>
        </section>

    </>)
}

export default MetalsPurchased