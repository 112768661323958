import React from "react";

const FerrousMetals = (props) => {
    return (<>
        <section id="ferrous-metals" className="action-parallax section-panel"
            style={{ "background": "rgba(255, 255, 255, 0.85)", "borderTop": "1px solid #ddd" }}>
            <div className="container-fluid text-center wow fadeIn">
                <h2 className="editable section-title col-md-offset-1" id="untitled-region-5">
                    Recycling<span className="section-title-sub">&nbsp;<i className="fa-solid fa-caret-right"></i>&nbsp;Ferrous Metals</span>
                </h2>
                <div className="row">
                    <div className="col-md-6 col-md-offset-1" style={{ "textAlign": "justify" }}>
                        <div className="entry-content">
                            <p style={{ "display": "none" }}>
                                <em>
                                    &#8220;Ferrous is an adjective used to indicate the presence of iron. The word is derived
                                    from the Latin word ferrum.&#8221;
                                </em><br />
                                <strong></strong>
                            </p>
                            <ul>
                                <li>
                                    <strong>Manganese Steel</strong> &#8211; Non-magnetic, hardened steel used in the mining
                                    industry, cement mixers, rock crushers, and other high impact and abrasive
                                    environments<strong></strong>
                                </li>
                                <li>
                                    <strong>Turnings</strong> &#8211; Usually the remains of drilling or shaping steels, also
                                    known as Borings and Swarf<strong></strong>
                                </li>
                                <li>
                                    <strong>Reo</strong> &#8211; Any type of re-inforcing bars or mesh usually used in the
                                    construction industry within concrete<strong></strong>
                                </li>
                                <li>
                                    <strong>Cast Iron</strong> &#8211; <em>Examples:</em> cast iron baths, machinery, pipe and
                                    engine blocks<strong></strong>
                                </li>
                                <li>
                                    <strong>Pressing Steel</strong> &#8211; Most Domestic scrap metal up to approximately 6mm
                                    thick. <em>Examples:</em> whitegoods (fridges, washing machines, etc), roofing iron, water
                                    heaters, water tanks and sheet metal offcuts<strong></strong>
                                </li>
                                <li>
                                    <strong>Car Bodies</strong> &#8211; Most vehicles with or without their original wheels and
                                    interiors. Sunshine Recycling also purchases all types of vehicle components.
                                    <strong></strong>
                                </li>
                                <li>
                                    <strong>HMS &#8211; Heavy Melting Steel</strong> &#8211; Most industrial or commercial scrap
                                    steel greater than 6mm thick. <em>Examples:</em> plate, beams, columns, channels, merchant
                                    bar, farm machinery or implements, metal stampings and offcuts, etc.
                                </li>
                            </ul>

                        </div>
                    </div>
                    <div className="col-md-4 col-md-offset-1">
                        <div className="embed-responsive embed-responsive-16by9">
                            <img id="untitled-region-7" className="img-responsive editable products-image"
                                src="../img/Iron-Pipe-737-348.jpg" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>);
}

export default FerrousMetals;