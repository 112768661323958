import React, { Component, useEffect, useState } from "react";
import SiteTitle from "./SiteTitle";

const NavBar = (props) => {
    SiteTitle(props.headerData.headerInfo.title)

    return (<>
        {/* Preloader */}
        <div style={{ display: 'none' }} id="preloader"><div id="status">&nbsp;</div></div>

        {/* Horizontal Navbar */}
        <nav id="mainNav" className="navbar navbar-default navbar-fixed-top" style={{ zIndex: '30000 !Important', overflow: 'visible !important' }}>
            <div className="container-fluid">
                <div className="col-lg-11">
                    <div className="navbar-header">
                        <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1">
                            <span className="sr-only">Toggle navigation</span>
                            <span className="icon-bar" />
                            <span className="icon-bar" />
                            <span className="icon-bar" />
                        </button>
                        <div id="contact-us-button" className="row">
                            <div className="col-xs-12 text-center editable" id="untitled-region-4">
                                <p>
                                    <a className="btn border-button-green page-scroll" href="#contact"><i className="fas fa-angle-down" />&nbsp;&nbsp;CONTACT US</a>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
                        <ul className="nav navbar-nav navbar-right editable" id="untitled-region-1">
                            <li className="repeatable"><a className="page-scroll" href="#top">Home</a></li>
                            <li className="dropdown repeatable">
                                <a className="page-scroll dropdown-toggle" data-toggle="dropdown" href="#">
                                    About
                                    <span className="caret" />
                                </a>
                                <ul className="dropdown-menu nav">
                                    <li className="repeatable"><a className="page-scroll page-scroll-a" href="#about">About</a></li>
                                    <li className="repeatable"><a className="page-scroll page-scroll-a" href="#contact">Contact</a></li>
                                    <li className="repeatable"><a className="page-scroll page-scroll-a" href="#faq">FAQ</a></li>
                                </ul>
                            </li>
                            <li className="dropdown repeatable">
                                <a className="page-scroll dropdown-toggle" data-toggle="dropdown" href="#">
                                    Recycling
                                    <span className="caret" />
                                </a>
                                <ul className="dropdown-menu nav">
                                    <li className="repeatable"><a className="page-scroll page-scroll-a" href="#recycling">Recycling</a></li>
                                    <li className="repeatable"><a className="page-scroll page-scroll-a" href="#ferrous-metals">Ferrous Metals</a></li>
                                    <li className="repeatable"><a className="page-scroll page-scroll-a" href="#non-ferrous-metals">Non Ferrous Metals</a></li>
                                    <li className="repeatable"><a className="page-scroll page-scroll-a" href="#prohibited-materials">Prohibited Materials</a></li>
                                    <li className="repeatable"><a className="page-scroll page-scroll-a" href="#roll-off-containers">Roll Off Containers</a></li>
                                    <li className="repeatable"><a className="page-scroll page-scroll-a" href="#vehicles-and-heavy-equipment">Vehicles &amp; Heavy Equipment</a></li>
                                    <li className="repeatable"><a className="page-scroll page-scroll-a" href="#metals-purchased">Metals Purchased</a></li>
                                    <li className="repeatable"><a className="page-scroll page-scroll-a" href="#plant">Plant</a></li>
                                </ul>
                            </li>
                            <li className="repeatable"><a className="page-scroll" href="#equipment">Equipment</a></li>
                            <li className="dropdown repeatable">
                                <a className="page-scroll dropdown-toggle" data-toggle="dropdown" href="#">
                                    Services
                                    <span className="caret" />
                                </a>
                                <ul className="dropdown-menu nav">
                                    <li className="repeatable"><a className="page-scroll page-scroll-a" href="#domestic-services">Domestic Service</a></li>
                                    <li className="repeatable"><a className="page-scroll page-scroll-a" href="#industrial-and-manufacturing">Industrial Manufacturing</a></li>
                                    <li className="repeatable"><a className="page-scroll page-scroll-a" href="#mobile-car-crushing">Mobile Car Crushing</a></li>
                                    <li className="repeatable"><a className="page-scroll page-scroll-a" href="#demolition-service">Demolition</a></li>
                                </ul>
                            </li>
                            <li className="dropdown repeatable">
                                <a className="page-scroll dropdown-toggle" data-toggle="dropdown" href="#">
                                    Gallery
                                    <span className="caret" />
                                </a>
                                <ul className="dropdown-menu nav">
                                    <li className="repeatable"><a className="page-scroll page-scroll-a" href="#customers">Customers</a></li>
                                    <li className="repeatable"><a className="page-scroll page-scroll-a" href="#industrial-photos">Industrial Photos</a></li>
                                    <li className="repeatable"><a className="page-scroll page-scroll-a" href="#domestic-photos">Domestic Photos</a></li>
                                    <li className="repeatable"><a className="page-scroll page-scroll-a" href="#videos">Video</a></li>
                                </ul>
                            </li>
                            <li className="dropdown repeatable">
                                <a className="page-scroll dropdown-toggle" data-toggle="dropdown" href="#">
                                    Aggregate
                                    <span className="caret" />
                                </a>
                                <ul className="dropdown-menu nav">
                                    <li className="repeatable"><a className="page-scroll page-scroll-a" href="#aggregate">Aggregate</a></li>
                                    <li className="repeatable"><a className="page-scroll page-scroll-a" href="#company-overview">Company Overview</a></li>
                                    <li className="repeatable"><a className="page-scroll page-scroll-a" href="#products-services">Products &amp; Services</a></li>
                                    <li className="repeatable"><a className="page-scroll page-scroll-a" href="#recycled-construction-aggregates">Recycled Aggregates</a></li>
                                    <li className="repeatable"><a className="page-scroll page-scroll-a" href="#project-page">Project Page</a></li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </nav>

    </>);
}
export default NavBar;