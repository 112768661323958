import React from 'react'

function IndustrialManufacturing() {
    return (<>
        {/* Industrial & Manufacturing */}
        <section id="industrial-and-manufacturing" className="action-parallax section-panel" style={{ background: 'rgba(255, 255, 255, 1.00)', borderTop: '1px solid #ddd' }}>
            <div className="container-fluid text-center wow fadeIn">
                <h2 className="editable section-title col-md-offset-1" id="untitled-region-5">Services<span className="section-title-sub">&nbsp;<i className="fa-solid fa-caret-right" />&nbsp;Industrial &amp; Manufacturing</span></h2>
                <div className="row">
                    <div className="col-md-6 col-md-offset-1" style={{ textAlign: 'justify' }}>
                        <div className="entry-content">
                            <p><strong />Sunshine Recycling can put its experience to work for your industrial or manufacturing organization. Our team begins with a thorough waste and reclamation audit so that we can better understand your waste processing practices. We then help you set productive and realistic goals for waste reduction, and we’ll provide monthly reports to monitor progress, productivity and profitability. We also offer customized recycling equipment designed specifically to meet your needs.</p>
                            <p>With over 16 years in service and a continued commitment to environmental and safety standards, our experience and expertise ensures only the best results. Whether you require a long term partner or a short term solution, Sunshine Recycling will cater to the need to properly assess your scrap metal service issues.<em> </em></p>
                            <p><em>We understand that for the majority of the time, scrap metal is a by-product and not the main focus of your business.</em>  With the growing strains on time and efficiencies, Sunshine Recycling can help with relieving the pressures of your KEY Personnel by letting OUR staff manage your recycling needs.</p>
                            <p>Sunshine Recycling’s continued commitment to the environmental and safety standards assures our clientele of work practices second to none. We offer the highest level of service with tailor made solutions covering all aspects of metal recycling.</p>
                            <p>Sunshine Recycling provides a Safe, Efficient and Reliable service both at our yard and onsite, ensuring a quick turn-around.</p>
                            <p>Sunshine Recycling has worked diligently with integrity and honesty to develop a loyal customer database from all levels of the community, whether it’s commercial or industrial, the knowledgeable Staff at Sunshine Recycling will help plan a scrap metal solution</p>
                        </div>{/* .entry-content */}
                    </div>
                    <div className="col-md-4 col-md-offset-1">
                        <div className="embed-responsive embed-responsive-16by9">
                            <img id="untitled-region-7" className="img-responsive editable products-image" src="img/commercial-services.jpg" />
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </>)
}

export default IndustrialManufacturing