import SunshineVideosData from "./SunshineVideosData";

function SunshineVideoa(props) {
    return (<>
        {/* Videos */}
        <section id="videos" className="action-parallax section-panel" style={{ background: 'rgba(255, 255, 255, 0.85)', borderTop: '1px solid #ddd' }}>
            <div className="container-fluid text-center wow fadeIn">
                <h2 className="editable section-title col-md-offset-1" id="untitled-region-5">Gallery<span className="section-title-sub">&nbsp;<i className="fa-solid fa-caret-right" />&nbsp;Videos</span></h2>
                <div style={{ height: '100px' }} />
                <div className="row">
                    <div className="col-md-10 inline-video col-md-offset-1">
                        <SunshineVideosData videos={props.videoList.videos} />
                    </div>
                </div>
            </div>
        </section>

    </>)
}

export default SunshineVideoa