import React from 'react'

function MobileCarCrushing() {
    return (<>
        {/* Mobile Car Crushing */}
        <section id="mobile-car-crushing" className="action-parallax section-panel" style={{ background: 'rgba(220, 220, 220, 0.85)', borderTop: '1px solid #ddd' }}>
            <div className="container-fluid text-center wow fadeIn">
                <h2 className="editable section-title col-md-offset-1" id="untitled-region-5">Services<span className="section-title-sub">&nbsp;<i className="fa-solid fa-caret-right" />&nbsp;Mobile Car Crushing</span></h2>
                <div className="row">
                    <div className="col-md-6 col-md-offset-1" style={{ textAlign: 'justify' }}>
                        <div className="entry-content">
                            <p>Sunshine Auto Salvage recycles all types of vehicles at our state-of-the-art Recycling division to reduce landfill waste, or we can visit you onsite with our <a title="Mobile Car Crushing Videos" href="http://www.sunshineautosalvage.com/mobile_car_crushing.php" target="_blank">mobile car crushing equipment</a>. Vehicles have all of the fluids recycled or reused as well as the Freon, mercury switches and tires. All of the metals are sorted and recycled. Sunshine Auto Salvage has a <a title="Gallery" href="http://www.sunshinerecycle.com/gallery/industrial-photo/" target="_blank">rigorous program</a> to reuse all of the parts that can be re-manufactured such as alternators, starters and air compressors.</p>
                            <p>Sunshine Auto Salvage has set the standard for environmental practices in South Carolina and continues to raise the bar to exceed the Federal and State guidelines. We have standardized our best management practices for managing vehicles and hazardous wastes. To learn more<a title="Mobile Car Crushing Videos" href="http://www.sunshineautosalvage.com/mobile_car_crushing.php" target="_blank"> click here.</a></p>
                        </div>{/* .entry-content */}
                    </div>
                    <div className="col-md-4 col-md-offset-1">
                        <div className="embed-responsive embed-responsive-16by9">
                            <img id="untitled-region-7" className="img-responsive editable products-image" src="img/crushed-cars.jpg" />
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </>)
}

export default MobileCarCrushing