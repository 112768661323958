import React from 'react'
import Helmet from 'react-helmet';

function MetaTags() {
    return (
        <>
            <Helmet>
                <meta name="description" content="Domestic &amp; industrial metals recovery &amp; recycling services, demolition, ferrous &amp; non-ferrous metals, scrap metal purchasing, mobile car crushing &amp; more" />
                <meta name="author" content="Sean.Sayed" />
                <meta name="twitter:card" content="summary" />
                <meta name="twitter:description" content="Domestic &amp; industrial metals recovery &amp; recycling services, demolition, ferrous &amp; non-ferrous metals, scrap metal purchasing, mobile car crushing &amp; more!" />
                <meta name="twitter:title" content="Sunshine Recycling | The Leaders in Recycling Technology and Innovation" />
                <meta name="msapplication-TileColor" content="#b91d47" />
                <meta name="theme-color" content="#ffffff" />

                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Sunshine Recycling | The Leaders in Recycling Technology and Innovation" />
                <meta property="og:description" content="Domestic &amp; industrial metals recovery &amp; recycling services, demolition, ferrous &amp; non-ferrous metals, scrap metal purchasing, mobile car crushing &amp; more!" />
                <meta property="og:url" content="http://www.sunshinerecycle.com/" />
                <meta property="og:site_name" content="Sunshine Recycling" />
                <meta property="place:location:latitude" content="33.5539143" />
                <meta property="place:location:longitude" content="-117.2139232" />
                <meta property="business:contact_data:street_address" content="" />
                <meta property="business:contact_data:locality" content="Orangeburg" />
                <meta property="business:contact_data:country_name" content="United States (US)" />
                <meta property="business:contact_data:postal_code" content="29115" />
                <meta property="business:contact_data:website" content="http://www.sunshinerecycle.com/" />
                <meta property="business:contact_data:region" content="South Carolina" />
                <meta property="business:contact_data:email" content="info@sunshinerecycle.com" />
                <meta property="business:contact_data:phone_number" content="803-531-4408" />
                <meta property="business:hours:day" content="monday" />
                <meta property="business:hours:start" content="10:00" />
                <meta property="business:hours:end" content="18:00" />
                <meta property="business:hours:day" content="tuesday" />
                <meta property="business:hours:start" content="10:00" />
                <meta property="business:hours:end" content="18:00" />
                <meta property="business:hours:day" content="wednesday" />
                <meta property="business:hours:start" content="10:00" />
                <meta property="business:hours:end" content="18:00" />
                <meta property="business:hours:day" content="thursday" />
                <meta property="business:hours:start" content="10:00" />
                <meta property="business:hours:end" content="18:00" />
                <meta property="business:hours:day" content="friday" />
                <meta property="business:hours:start" content="10:00" />
                <meta property="business:hours:end" content="18:00" />
                <meta property="business:hours:day" content="saturday" />
                <meta property="business:hours:start" content="10:00" />
                <meta property="business:hours:end" content="18:00" />
            </Helmet>
        </>
    )
}

export default MetaTags