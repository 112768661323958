import React from 'react'

function Aggregate() {
    return (<>
        {/* Aggregate */}
        <section id="aggregate" className="action-parallax section-panel" style={{ background: 'rgba(255, 255, 255, 1.0)', borderTop: '1px solid #ddd' }}>
            <div className="container-fluid text-center wow fadeIn">
                <h2 className="editable section-title col-md-offset-1" id="untitled-region-5">Aggregate<span className="section-title-sub">&nbsp;<i className="fa-solid fa-caret-right" />&nbsp;Aggregate</span></h2>
                <div className="row">
                    <div className="col-md-5 col-md-offset-1" style={{ textAlign: 'justify' }}>
                        <div className="entry-content">
                            <p>
                                <strong>Concrete recycling</strong> is becoming an increasingly popular way to utilize aggregate left
                                behind when structures or roadways are demolished. In the past, this rubble was
                                disposed of in landfills, but with more attention being paid to environmental concerns,
                                concrete recycling allows reuse of the rubble while also keeping construction costs
                                down.
                            </p>
                            <p>
                                The recycling and reuse of concrete aggregate makes sense. Here are some of the
                                benefits:
                            </p>
                            <ul>
                                <li>
                                    Produce specification sized recycled aggregates at your location
                                </li>
                                <li>
                                    Avoid haul-off costs and landfill disposal fees
                                </li>
                                <li>
                                    Eliminate the expense of aggregate material imports and exports
                                </li>
                                <li>
                                    Increase project efficiency and improve job cost – recycled concrete
                                    aggregates yield more volume by weight (up to 15%)
                                </li>
                                <li>
                                    Minimize impact to community infrastructure by reducing import and export
                                    trucking.
                                </li>
                            </ul>
                            <p>&nbsp;</p>
                        </div>{/* .entry-content */}
                    </div>
                    <div className="col-md-5 col-md-offset-1">
                        <div className="embed-responsive embed-responsive-16by9">
                            <iframe src="http://www.youtube-nocookie.com/embed/8weijGEQ9IU?rel=0" className="embed-responsive-item products-video" frameBorder={0} />
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </>)
}

export default Aggregate