import React from 'react'
import CustomerData from "./CustomerData";

function Customers(props) {
    return (<>
        {/* Customers */}
        <section id="customers" className="action-parallax section-panel" style={{ background: 'rgba(255, 255, 255, 0.85)', borderTop: '1px solid #ddd' }}>
            <div className="container-fluid text-center wow fadeIn">
                <h2 className="editable section-title col-md-offset-1" id="untitled-region-5">Gallery<span className="section-title-sub">&nbsp;<i className="fa-solid fa-caret-right" />&nbsp;Customers</span></h2>
                <div style={{ height: '100px' }} />
                <div className="row">
                    <div className="col-md-10 col-md-offset-1" style={{ textAlign: 'left' }}>
                        <div className="entry-content">
                            <div className="portfolio wow fadeIn">
                                <ul id="team-portfolio-slider" className="editable-gallery" data-links="false" data-thumbnail-width={400} data-thumbnail-height={267} data-thumbnail-resize="best-fit">
                                    <CustomerData customers={props.customerList.customers} />
                                </ul>
                            </div>
                        </div>{/* .entry-content */}
                    </div>
                </div>
            </div>
        </section>

    </>)
}

export default Customers