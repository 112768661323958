import React from 'react'

function DomesticPhotosData(props) {
    var result = [];
    for (var i in props)
        result.push([i, props[i]]);

    return (
        result[0][1].map((_domestic) => (
            <li><img src={_domestic.image} alt={_domestic.alt} /></li>
        ))

    )
}

export default DomesticPhotosData