import React from 'react'

function Equipment() {
    return (<>
        {/* Equipment */}
        <section id="equipment" className="section-panel" style={{ color: '#000 !Important', background: 'rgba(255, 255, 255, 0.85)', borderTop: '1px solid #ddd' }}>
            <div className="container-fluid text-center wow fadeIn">
                <div className="row">
                    <div className="col-xs-12 text-center wow fadeIn">
                        <h2 className="editable section-title col-md-offset-1" id="untitled-region-10">EQUIPMENT</h2>
                        <div className="row equipment-details">
                            <div className="col-md-6 col-md-offset-1" style={{ textAlign: 'justify' }}>
                                <p className="lead editable" id="untitled-region-6">
                                </p><p><strong>Mobile and Onsite</strong></p>
                                <ul>
                                    <li><strong>5-2012 Terex Fuchs MHL 360D Material Handlers</strong> – Rubber Tired<strong /></li>
                                    <li><strong>1-2012 Terex Fuchs MHL 340</strong> – Rubber Tired<strong /></li>
                                    <li><strong>1-2010 Terex Fuchs MHL 360D “Hybrid” – Labounty 3000 Shear attached</strong> – Rubber Tired<strong /></li>
                                    <li><strong>2012 Doosan DX 225 LC Excavator with Labounty BLS 80 Series Shear</strong> – Bucket Link Shear. Can attach CP80 concrete processor<strong /></li>
                                    <li><strong>2012 Doosan 300 LC Excavator</strong><strong /></li>
                                    <li><strong>3 – Hyster 190 Forklifts</strong> – Solid Rubber Tired with 16,000 lbs lift capacity<strong /></li>
                                    <li><strong>6 – Hyster 180</strong> – 15,000 lbs Solid Rubber Tired<strong /></li>
                                    <li><strong>2 – Sierra 6000 Hi-Density Mobile Balers</strong></li>
                                    <li><strong>JCB 120</strong></li>
                                    <li><strong>Huber F1500 Motor Grader</strong></li>
                                    <li><strong>Forklifts</strong> – 30 – 60 and 80 Series</li>
                                </ul>
                                <p>
                                    <strong>Spectrometer  Metal Analysis – </strong><a title="Spectrometer Metal Analysis Sunshine Recycling" href="http://www.olympus-ims.com/en/innovx-xrf-xrd/" target="_blank">
                                        click here for more information<strong>
                                            <br />
                                        </strong>
                                    </a>
                                </p>
                                <p><strong>Roll Off Containers Service Equipment</strong></p>
                                <ul>
                                    <li>Fleet of over 100 Roll-Off Containers from Custom designed Five Yard miniatures to large capacity 80 yard containers</li>
                                    <li>Benlee Super Mini Roll-Off Trailer.  Spotting the 5, 10, 20, 30, 40 yard Roll-Off containers. Provides for added weight capacity</li>
                                    <li>Three – Peterbilt Tandem Axle Roll Off</li>
                                </ul>
                                <p><strong>Trailers</strong></p>
                                <ul>
                                    <li>Eight – Transcraft TL 2000 Superbeamed Flatbeds – High Capacity</li>
                                    <li>Three – Manac 40’- Eighty Yard Dump Trailers supported by our Trucking Fleet</li>
                                    <li>One – Transcraft TL 2000  53’ Aluminum Flatbed</li>
                                    <li>Six – Stoughton Enclosed Band Trailers</li>
                                    <li>Two – 30 yard Lined Dump Trailers.  One Aluminum and One Steel</li>
                                    <li>One – Ferguson Compactor Roller</li>
                                </ul>
                                <p><strong>Bulldozers</strong></p>
                                <ul>
                                    <li>Komatzu 39PX Enclosed Cab</li>
                                    <li>Komatzu 41P Enclosed Cab</li>
                                </ul>
                                <p><strong>Trucks</strong></p>
                                <ul>
                                    <li>2006 Terex TA30 – High Capacity Off-Road 30 Ton Dump Truck</li>
                                </ul>
                                <p><strong>On Site Fire Trucks</strong></p>
                                <ul>
                                    <li>FMC – 3000 gallon capacity</li>
                                    <li>Mobile Brush – 1000 gallon capacity</li>
                                </ul>
                                <p><strong>ForkLift Hoppers</strong></p>
                                <ul>
                                    <li>60 small Self Dumping Forklift Hoppers</li>
                                </ul>
                                <p><strong>Concrete Crusher</strong></p>
                                <ul>
                                    <li><strong>2012 Terex Power Screen MetroTrac</strong> and the<strong> Powerscreen® Chieftain 1400</strong> which gives us the Recycling capacity to Process up to 220 tons per hour.</li>
                                </ul>
                                <p><strong>Service Trucks</strong></p>
                                <ul>
                                    <li>Four – Onsite-Offsite Fully Equipped Service Trucks. Equipment includes Welding Machines, Air Compressors, Lube Packs.</li>
                                    <li>One – 40’ Evaco Landoll Trailer.  Reclamation of Heavy Equipment.</li>
                                </ul>
                                <p>
                                    <strong> Two Mobil Magnum Light Plants</strong><br />
                                    <strong> </strong>
                                </p>
                                <p><strong>Semi Trucks</strong></p>
                                <ul>
                                    <li>Kenworth</li>
                                    <li>Peterbilt</li>
                                </ul>
                                <p>&nbsp;</p>
                            </div>
                            <div className="col-md-4 col-md-offset-1">
                                <div className="embed-responsive embed-responsive-16by9 equipment-image">
                                    <img id="untitled-region-71" className="img-responsive editable products-image" src="img/sem-lowboy.jpg" style={{ borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }} />
                                </div>
                                <div className="embed-responsive embed-responsive-16by9 equipment-image">
                                    <img id="untitled-region-72" className="img-responsive editable products-image" src="img/trk-doz-trx.jpg" />
                                </div>
                                <div className="embed-responsive embed-responsive-16by9 equipment-image">
                                    <img id="untitled-region-73" className="img-responsive editable products-image" src="img/sr-great-photo.jpg" />
                                </div>
                                <div className="embed-responsive embed-responsive-16by9 equipment-image">
                                    <img id="untitled-region-74" className="img-responsive editable products-image" src="img/ob-lwby-trx-semi.jpg" />
                                </div>
                                <div className="embed-responsive embed-responsive-16by9 equipment-image">
                                    <img id="untitled-region-75" className="img-responsive editable products-image" src="img/metrotrak.jpg" />
                                </div>
                                <div className="embed-responsive embed-responsive-16by9 equipment-image">
                                    <img id="untitled-region-76" className="img-responsive editable products-image" src="img/joe-trx-close.jpg" style={{ borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px' }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </>)
}

export default Equipment